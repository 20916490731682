var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dn-home"},[_c('hero-section'),_c('div',{staticClass:"dn-home__main dn-container"},_vm._l((_vm.collections),function(section,i){return _c('carousel',{key:i,staticClass:"dn-home__section",attrs:{"title":section.name,"overflow":_vm.$vuetify.breakpoint.smAndDown,"type":section.videos.length ? 'video' : 'pack',"items":section.videos.length ? section.videos : section.packs,"perView":section.videos.length ? 4 : 6,"gap":_vm.$vuetify.breakpoint.mdAndUp ? 24 : 16,"breakpoints":{
        800: {
          perView: section.videos.length ? 1.3 : 2.1,
        },
        1600: {
          perView: section.videos.length ? 2 : 3,
        },
      }}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }