











import Vue from 'vue';
import { mapState } from 'vuex';

import Carousel from './Carousel.vue';

export default Vue.extend({
  components: {
    Carousel,
  },
  computed: {
    ...mapState({
      banners: (state: any) => state.home.banners,
    }),
    breakpoints() {
      return {
        800: {
          perView: 1.1,
        },
        1600: {
          perView: 2.2,
        },
      };
    },
  },
});
